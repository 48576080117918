<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/temp/banner_jjjb.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${apiName}report?t=${index}`">{{item.title}}</a>
                <span></span>
              </div>
              <!-- <div class="listSlidListInner">
                <a href="/list?t=1">项目动态</a>
                <span></span>
              </div> -->
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd listHdPage clearfloat">
              <div class="fl listHdName">{{hdTitle}}</div>
              <div class="fr listHdGrop"><a :href="`${apiName}`">首页</a> · <a :href="`${apiName}report`">{{slideHd.title}}</a> · <span class="txt">{{hdTitle}}</span> </div>
            </div>
            <div class="listContainer">
              <div class="reportWrap">
                <el-form ref="ruleForm" :model="form" :rules="rules" label-width="150px">
                  <div class="reportInner">
                    <div class="retportHd">举报人信息</div>
                    <div class="reportGrop">
                      <el-form-item label="举报人姓名：" prop="whistleblower_name">
                        <el-input v-model="form.whistleblower_name" clearable placeholder="请输入举报人姓名"></el-input>
                      </el-form-item>
                      <el-form-item label="联系电话：" prop="whistleblower_phone">
                        <el-input v-model="form.whistleblower_phone" type="number" clearable placeholder="请输入联系电话"></el-input>
                      </el-form-item>
                      <el-form-item label="您的单位或地址：" prop="whistleblower_address">
                        <el-input v-model="form.whistleblower_address" clearable placeholder="请输入您的单位或地址"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="reportInner">
                    <div class="retportHd">被举报人信息</div>
                    <div class="reportGrop">
                      <el-form-item label="被举报人姓名：" prop="report_person_name">
                        <el-input v-model="form.report_person_name" clearable placeholder="请输入被举报人姓名"></el-input>
                      </el-form-item>
                      <el-form-item label="被举报人单位：" prop="report_person_company">
                        <el-input v-model="form.report_person_company" clearable placeholder="请输入被举报人单位"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="reportInner">
                    <div class="retportHd">举报信息</div>
                    <div class="reportGrop">
                      <el-form-item label="举报标题：" prop="report_tittle">
                        <el-input v-model="form.report_tittle" clearable placeholder="请输入举报标题"></el-input>
                      </el-form-item>
                      <el-form-item label="举报内容：" prop="report_content">
                        <el-input
                          v-model="form.report_content"
                          type="textarea"
                          :rows="4"
                          clearable
                          placeholder="请输入举报内容"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="验证码:" prop="codeForm">
                        <el-input
                          v-model="codeForm"
                          type="number"
                          clearable
                          placeholder="请输入验证码"
                          style="width: calc(100% - 150px)"
                        ></el-input>
                        <div class="codeImg">
                          <s-identify
                            style="display:inline-block; width: 100%; height:40px;vertical-align:top;cursor:pointer;"
                            :identifyCode="code"
                            :contentHeight="40"
                            :contentWidth="140"
                            @click.native="handleRefreshCode"
                          />
                        </div>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" :loading="loading" @click="submitForm('ruleForm')">立即提交</el-button>
                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import SIdentify from './captcha/index.vue'
// import { getChildChannel, addAccuseInform } from '@/api/common'
// import inside from './inside.vue'
// import subcompany from './subcompany.vue'
// import outside from './outside.vue'
export default {
  components: {
    SIdentify
    // subcompany,
    // outside
  },
  data() {
    // var checkPhone = (rule, value, callback) => {
    //   const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
    //   if (!value) {
    //     return callback(new Error('电话号码不能为空'))
    //   }
    //   setTimeout(() => {
    //     // Number.isInteger是es6验证数字是否为整数的方法,实际输入的数字总是识别成字符串
    //     // 所以在前面加了一个+实现隐式转换

    //     if (!Number.isInteger(+value)) {
    //       callback(new Error('请输入数字值'))
    //     } else {
    //       if (phoneReg.test(value)) {
    //         callback()
    //       } else {
    //         callback(new Error('电话号码格式不正确'))
    //       }
    //     }
    //   }, 100)
    // }
    return {
      codeForm: '',
      hdTitle: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '纪检举报',
        sub: 'REPORT',
      },
      slideList:[
      ],
      loading: false,
      code: '',
      form: {
        whistleblower_name: '',
        whistleblower_phone: '',
        whistleblower_address: '',
        report_person_name: '',
        report_person_company: '',
        report_tittle: '',
        report_content: '',
        company_id: this.companyId,
      },
      rules: {
        // whistleblower_name: [{ required: true, message: '请输入举报人姓名', trigger: 'blur' }],
        // whistleblower_phone: [
        //   { required: true, message: '请输入举报人电话', trigger: 'blur' },
        //   { validator: checkPhone, trigger: 'blur' }
        // ],
        report_person_name: [{ required: true, message: '请输入被举报人姓名', trigger: 'blur' }],
        report_person_company: [{ required: true, message: '请输入被举报人单位', trigger: 'blur' }],
        report_content: [{ required: true, message: '请输入举报内容', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      listId: '',
      isDeail: false,
      isList: false,
      fullpath: '/groupProfile/companyProfile',
      pathtitle: '',
      mathedTitle: '集团概况',
      navSubList: [],
      organizationHdList: [
        {
          name: '内部机构'
        },
        {
          name: '子公司'
        },
        {
          name: '驻外机构'
        }
      ],
      activeHd: 1
    }
  },
  computed: {},
  mounted() {
    var _this = this

    this.handleRefreshCode()
    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    // _this.getSubGrop()
  },
  methods: {
    handleRefreshCode() {
      this.code = (Math.random() * 8999 + 1000).toFixed(0).toString()
    },
    submitForm(formName) {
      var _this = this
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true
          if (!this.codeForm.length) {
            this.$message({
              type: 'warning',
              message: '请输入验证码!'
            })
            this.loading = false
            return
          }
          if (this.codeForm != this.code) {
            this.$message({
              type: 'warning',
              message: '验证码错误!'
            })
            this.loading = false
            return
          }

          _this.$http.post(
            _this.apiUrl + '/complaint/addDisciplinaryInspectionReport', 
            _this.form
          ).then(res => {
            const { code, msg } = res.data
            if (code === 200) {
              this.loading = false
              this.$confirm('提交成功, 我们会及时联系您！', '提示', {
                showConfirmButton: false,
                cancelButtonText: '确定',
                type: 'success',
                center: true
              })
                .then(() => {})
                .catch(() => {
                  _this.resetForm('ruleForm')
                  _this.codeForm = ''
                })
            } else {
              this.loading = false
              this.$message({
                type: 'warning',
                message: msg + '提交失败，请稍后重试!'
              })
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 获取二级栏目
    getSubGrop() {
      // var _this = this
      // let data = {
      //   applicationUuid: _this.webId,
      //   parentUuid: '0018'
      // }
      // getChildChannel(data).then(res => {
      //   const { code, data } = res
      //   if (code === 1 && data) {
      //     _this.navSubList = data
      //     data.length &&
      //       data.forEach(line => {
      //         if (line.entTChannelUuid == _this.listId) {
      //           _this.pathtitle = line.name
      //           if (line.catalog == 1) {
      //             this.isDeail = true
      //           } else {
      //             this.isList = true
      //           }
      //         }
      //       })
      //   }
      // })
    },
    handleTab(item) {
      this.isDeail = false
      this.isList = false
      this.$router.push(`/groupProfile/${item.entTChannelUuid}`)
      this.pathtitle = item.name
      this.active = item.entTChannelUuid
      this.listId = item.entTChannelUuid
      this.$nextTick(() => {
        if (item.catalog == 1) {
          this.isDeail = true
        } else {
          this.isList = true
        }
      })
    },
    mouseenterHd(val) {
      var _this = this

      _this.activeHd = val
    }
  },
  watch: {
    $route(val) {
      this.fullpath = val.fullPath
      // this.pathtitle = val.meta.title
      // this.mathedTitle = val.matched[val.matched.length - 2].meta.title
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff2?t=1644917438066') format('woff2'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff?t=1644917438066') format('woff'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.ttf?t=1644917438066') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 22px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}

.report {
  width: 100%;
  height: auto;
  overflow: hidden;
  min-height: 902px;
}
.report .ziHead {
  width: 100%;
  height: 417px;
  overflow: hidden;
  /* background: url(../../assets/image/zihead.jpg) center no-repeat; */
  background-size: 100% 100%;
}

.report .ziNav {
  height: 42px;
  border-bottom: 1px #cacaca solid;
  margin-top: 15px;
  width: 100% !important;
}
.report .navMain {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.report .navMainLeft {
  width: 900px;
  float: left;
}
.report .navMainLeft a {
  height: 42px;
  line-height: 42px;
  display: inline-block;
  margin: 0px 5px;
  padding: 0px 10px;
  float: left;
}
.report .navMainLeft a:hover {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.report .navMainLeft a.active {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.report .navMainRight {
  width: 300px;
  float: right;
  overflow: hidden;
  font-size: 13px;
  color: #a2a2a2;
  margin-top: 12px;
  line-height: 24px;
}
.report .navMainRight span {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
  vertical-align: top;
}

.report .navMainRight .textTitle {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
}
.report .ziBase {
  width: auto;
  height: auto;
  overflow: hidden;
}
.report .ziContact {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.report .rowContact {
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 1px 4px #333333;
  padding: 30px 50px;
  background-color: #ffffff;
  line-height: 24px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.navMainInner {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  height: 42px;
  line-height: 43px;
  box-sizing: border-box;
    font-size: 17px;
}
.navMainInner.active {
  border-bottom: 2px solid #0263b2;
}
.organizationTitle {
  line-height: 60px;
  text-align: center;
  font-size: 24px;
}
.organizationHd {
  text-align: center;
  margin: 20px 0;
}
.organizationHd span {
  display: inline-block;
  width: 248px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  background-color: #ebf1f5;
  cursor: pointer;
}
.organizationHd span.active {
  background-color: #0162b3;
  color: #ffffff;
}
.reportWrap {
  padding: 6px 10px;
  /* background-color: #f2f2f2; */
}
.reportInner {
  background-color: #fff;
  padding-bottom: 2px;
  margin-bottom: 12px;
}
.reportInner:last-child {
  margin-bottom: 0;
}
.retportHd {
  height: 42px;
  line-height: 42px;
  padding: 0 15px;
  border-bottom: 1px solid #e5e5e5;
  color: #333;
  border-radius: 2px 2px 0 0;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}
.codeImg {
  display: inline-block;
  width: 140px;
  height: 40px;
  margin-left: 10px;
}
.reportGrop {
  width: 80%;
}
@media (max-width: 1300px) {
  .report .navMain,
  .companyProfile,
  .report .rowContact {
    width: 1000px;
  }
  .report .navMainLeft,
  .report .navMainRight {
    width: auto;
  }
}
@media (max-width: 992px) {
  .report .navMain,
  .companyProfile,
  .report .rowContact {
    width: 96%;
  }
  .report .rowContact {
    padding: 20px 10px;
  }
  .companyProfile {
    padding: 20px 20px;
  }
  .companyProfile .contactBody video {
    max-width: 100% !important;
  }
  .companyProfile img {
    max-width: 100% !important;
  }
  .report .ziHead {
    height: 362px;
  }
}
@media (max-width: 768px) {
  .report .navMainLeft {
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .report .ziHead {
    height: 200px;
  }
  .report .ziContact {
    padding: 0;
    margin: 0;
  }
  .companyProfile {
    padding: 20px 10px;
  }
  .contactBody h1 {
    font-size: 22px !important;
  }
  .report .rowContact {
    padding: 0px 0;
  }
  .reportGrop {
    width: 100% !important;
  }
  .reportGrop .el-form-item__label {
    width: 120px !important;
  }
}
</style>
<style>
@media (max-width: 768px) {
  .reportGrop {
    width: 100% !important;
  }
  .report .el-form-item__label {
    width: 112px !important;
    font-size: 12px !important;
    padding: 0 4px 0 0 !important;
  }
  .report .el-form-item__content {
    margin-left: 112px !important;
    margin-right: 6px !important;
  }
  .reportWrap {
    padding: 0 !important;
  }
}
</style>
